import { Chip, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ApplicationId, IAppRecordDescription, ApplicationOwnedProperties, LicenseSubtype } from '../../data/Helpers'
import MinusIcon from '@mui/icons-material/ArrowDownward'
import PlusIcon from '@mui/icons-material/ArrowUpward'
import NoChangeIcon from '@mui/icons-material/FeaturedPlayList'
import UnknownIcon from '@mui/icons-material/Help'
import { GridColDef } from '@mui/x-data-grid'

interface IRenderApplicationOffsetControl {
  amount: number
  appId: ApplicationId
  readOnlyAccess: boolean
  setApplicationOwnedProperties: React.Dispatch<React.SetStateAction<ApplicationOwnedProperties>>
}

const RenderApplicationOffsetControl = (props: IRenderApplicationOffsetControl) => {
  const { amount, appId, readOnlyAccess, setApplicationOwnedProperties } = props

  return (
    <TextField
      disabled={readOnlyAccess}
      id='outlined-number'
      label='Amount'
      type='number'
      size='small'
      value={amount}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setApplicationOwnedProperties((prevState) => ({
          ...prevState,
          [appId]: { ...prevState[appId], amount: Number(e.target.value) < 0 ? 0 : Number(e.target.value) }
        }))
      }}
    />
  )
}

interface IRenderLicenseSubtypeOffsetControl {
  amount: number
  licenseSubtype: LicenseSubtype
  licenseSubtypeAmount: number | undefined
  appId: ApplicationId
  readOnlyAccess: boolean
  setApplicationOwnedProperties: React.Dispatch<React.SetStateAction<ApplicationOwnedProperties>>
}
const RenderLicenseSubtypeOffsetControl = (props: IRenderLicenseSubtypeOffsetControl) => {
  const { amount, licenseSubtype, licenseSubtypeAmount, appId, readOnlyAccess, setApplicationOwnedProperties } = props
  const [value, setValue] = useState<number>(licenseSubtypeAmount ?? 0)

  if (licenseSubtypeAmount === undefined) return null

  useEffect(() => {
    if (licenseSubtypeAmount !== value) {
      setValue(licenseSubtypeAmount)
    }
  }, [licenseSubtypeAmount])

  return (
    <TextField
      disabled={readOnlyAccess}
      id='outlined-number'
      label='Amount'
      type='number'
      size='small'
      value={value}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const diff = Number(e.target.value) - value
        setApplicationOwnedProperties((prevState) => ({
          ...prevState,
          [appId]: {
            ...prevState[appId],
            amount: amount + diff < 0 ? 0 : Number(amount + diff),
            [licenseSubtype]: Number(e.target.value) < 0 ? 0 : Number(e.target.value)
          }
        }))
        setValue(Number(e.target.value))
      }}
    />
  )
}

const RenderCreationDate = ({ date }: { date: number }) => {
  if (!date || date === 0) {
    return <></>
  }
  const formattedDate = new Date(date * 1000).toISOString().slice(0, -8)

  return <TextField id='outlined-number' type='datetime-local' size='small' value={formattedDate} disabled />
}

interface IRenderExpirationDate {
  date: number
  appId: ApplicationId
  readOnlyAccess: boolean
  applicationCollectionData: Record<string, IAppRecordDescription>
  setApplicationOwnedProperties: React.Dispatch<React.SetStateAction<ApplicationOwnedProperties>>
}

const RenderExpirationDate = (props: IRenderExpirationDate) => {
  const { date, appId, readOnlyAccess, applicationCollectionData, setApplicationOwnedProperties } = props
  const formattedDate = new Date(date * 1000).toISOString().slice(0, -8)
  const [value, setValue] = useState<string>(formattedDate)

  if (!applicationCollectionData[appId as keyof typeof applicationCollectionData].isSubscription) {
    return <></>
  }

  return (
    <TextField
      disabled={readOnlyAccess}
      id='outlined-number'
      type='datetime-local'
      size='small'
      value={value}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value)
        const newDate = new Date(e.target.value)
        const expirationDate = Math.trunc(newDate.getTime() / 1000 - newDate.getTimezoneOffset() * 60)
        setApplicationOwnedProperties((prevState) => ({
          ...prevState,
          [appId]: { ...prevState[appId], expirationDate }
        }))
      }}
    />
  )
}

interface IRenderEntitlementAccountChange {
  appId: ApplicationId
  applicationOwnedProperties: ApplicationOwnedProperties
  initialApplicationOwnedProperties: ApplicationOwnedProperties
  loading: boolean
}
const RenderEntitlementAccountChange = (props: IRenderEntitlementAccountChange) => {
  const { appId, applicationOwnedProperties, initialApplicationOwnedProperties, loading } = props
  const amountDiff =
    (Number(applicationOwnedProperties[appId].amount) ?? 0) -
    (Number(initialApplicationOwnedProperties[appId].amount) ?? 0)
  const domesticDiff =
    (Number(applicationOwnedProperties[appId].domestic) ?? 0) -
    (Number(initialApplicationOwnedProperties[appId].domestic) ?? 0)
  const internationalDiff =
    (Number(applicationOwnedProperties[appId].international) ?? 0) -
    (Number(initialApplicationOwnedProperties[appId].international) ?? 0)
  let expirationDiff =
    (Number(applicationOwnedProperties[appId].expirationDate) ?? 0) -
    (Number(initialApplicationOwnedProperties[appId].expirationDate) ?? 0)
  expirationDiff /= 86400 // Converted to days

  const chipColor = (diff: number) => {
    if (isNaN(diff)) {
      return 'info'
    }
    if (diff === 0) {
      return 'primary'
    }
    if (diff > 0) {
      return 'success'
    }
    return 'error'
  }
  const chipIcon = (diff: number) => {
    if (isNaN(diff)) {
      return <UnknownIcon fontSize='small' />
    }
    if (diff === 0) {
      return <NoChangeIcon fontSize='small' />
    }
    if (diff > 0) {
      return <PlusIcon fontSize='small' />
    }
    return <MinusIcon fontSize='small' />
  }

  if (loading) {
    return <CircularProgress color='primary' />
  }

  if (!amountDiff && !domesticDiff && !internationalDiff && !expirationDiff) {
    return <Typography variant='overline'>No Change</Typography>
  }

  return (
    <>
      {(amountDiff || domesticDiff || internationalDiff) && (
        <Chip
          label={`${amountDiff} license/s`}
          color={chipColor(amountDiff)}
          icon={chipIcon(amountDiff)}
          variant='filled'
        />
      )}
      {expirationDiff !== 0 && (
        <Chip
          label={`${expirationDiff.toFixed(2)} day/s`}
          color={chipColor(expirationDiff)}
          icon={chipIcon(expirationDiff)}
          variant='filled'
        />
      )}
    </>
  )
}

interface IGetColumns {
  readOnlyAccess: boolean
  applicationCollectionData: Record<ApplicationId, IAppRecordDescription>
  applicationOwnedProperties: ApplicationOwnedProperties
  setApplicationOwnedProperties: React.Dispatch<React.SetStateAction<ApplicationOwnedProperties>>
  initialApplicationOwnedProperties: ApplicationOwnedProperties
  loading: boolean
}

//#region GetColumns
export const getColumns = (props: IGetColumns): GridColDef[] => {
  const {
    readOnlyAccess,
    applicationCollectionData,
    applicationOwnedProperties,
    setApplicationOwnedProperties,
    initialApplicationOwnedProperties,
    loading
  } = props

  const columns: GridColDef[] = [
    {
      field: 'app_name',
      headerName: 'Application',
      width: 200
    },
    {
      field: 'offset_control',
      headerName: 'License Amount Owned',
      renderCell: (params) => {
        return RenderApplicationOffsetControl({
          amount: applicationOwnedProperties[params.value?.appId as ApplicationId].amount ?? 0,
          appId: params.value.appId,
          readOnlyAccess:
            readOnlyAccess || params.value.domestic != undefined || params.value.international != undefined,
          setApplicationOwnedProperties
        })
      },
      width: 200
    },
    {
      field: 'offset_domestic',
      headerName: 'Domestic Licenses',
      renderCell: (params) => {
        return RenderLicenseSubtypeOffsetControl({
          amount: applicationOwnedProperties[params.value?.appId as ApplicationId].amount ?? 0,
          licenseSubtype: 'domestic',
          licenseSubtypeAmount: params.value?.domestic,
          appId: params.value?.appId,
          readOnlyAccess,
          setApplicationOwnedProperties
        })
      },
      width: 170
    },
    {
      field: 'offset_international',
      headerName: 'International Licenses',
      renderCell: (params) => {
        return RenderLicenseSubtypeOffsetControl({
          amount: applicationOwnedProperties[params.value?.appId as ApplicationId].amount ?? 0,
          licenseSubtype: 'international',
          licenseSubtypeAmount: params.value?.international,
          appId: params.value?.appId,
          readOnlyAccess,
          setApplicationOwnedProperties
        })
      },
      width: 170
    },
    {
      field: 'creation_date',
      headerName: 'Creation Date',
      renderCell: (params) => RenderCreationDate(params.value),
      width: 250
    },
    {
      field: 'expiration_date',
      headerName: 'Expiration Date',
      renderCell: (params) =>
        RenderExpirationDate({
          ...params.value,
          readOnlyAccess,
          applicationCollectionData,
          setApplicationOwnedProperties
        }),
      width: 250
    },
    {
      field: 'difference',
      headerName: 'Change',
      renderCell: (params) =>
        RenderEntitlementAccountChange({
          ...params.value,
          applicationOwnedProperties,
          initialApplicationOwnedProperties,
          loading
        }),
      width: 300
    }
  ]
  return columns
}
//#endregion GetColumns
