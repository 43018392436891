import { gql } from '@apollo/client'

export const GET_EXPORTS_COUNT = gql`
  query getExportsByUsername($username: String!) {
    getExportsByUsername(username: $username) {
      accountID
      creationDate
      designID
      freehandParts
      ip
      userID
    }
  }
`
