import { Button, Stack } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface ExportsDateFiltersProps {
  startDate: Dayjs | null
  endDate: Dayjs | null
  setStartDate: (date: Dayjs | null) => void
  setEndDate: (date: Dayjs | null) => void
}
export const ExportsDateFilters = ({ startDate, setStartDate, endDate, setEndDate }: ExportsDateFiltersProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en'>
      <Stack direction='row' spacing={2} alignItems='center'>
        <DateTimePicker
          label='Start Date (UTC)'
          value={startDate ? dayjs.utc(startDate) : null}
          onChange={(newValue) => setStartDate(newValue)}
          ampm={false}
          timezone='UTC'
        />
        <DateTimePicker
          label='End Date (UTC)'
          value={endDate ? dayjs.utc(endDate) : null}
          onChange={(newValue) => setEndDate(newValue)}
          ampm={false}
          timezone='UTC'
        />
        <Button
          variant='outlined'
          onClick={() => {
            setStartDate(null)
            setEndDate(null)
          }}
        >
          Clear Date Filters
        </Button>
      </Stack>
    </LocalizationProvider>
  )
}
