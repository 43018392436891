import { useEffect, useState } from 'react'
import { Grid, LinearProgress, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridOverlay, GridToolbar } from '@mui/x-data-grid'
import SearchBarComponent from '../components/SearchBarComponent'
import { ExportsDateFilters } from '../components/exportsCount/ExportsDateFilters'
import { useLazyQuery } from '@apollo/client'
import { GET_EXPORTS_COUNT } from '../graphql/queries/exportsCountQueries'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import SearchUserIcon from '@mui/icons-material/SearchTwoTone'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface ExportRecord {
  accountID: string
  userID: string
  creationDate: string //in milliseconds
  designID: string
  freehandParts: string[]
  ip: string
}

interface GetExportsByUsernameData {
  getExportsByUsername: ExportRecord[]
}

interface ISoftwareFlagsMenuProps {
  handleSearchChange: (value: string) => void
  handleSearch: () => void
  searchValue: string
  loading: boolean
}

const SoftwareFlagsMenu = ({ handleSearch, handleSearchChange, searchValue, loading }: ISoftwareFlagsMenuProps) => (
  <>
    <SearchBarComponent
      handleSearchChange={handleSearchChange}
      handleSearch={handleSearch}
      searchTextValue={searchValue}
      placeHolderSearchText='Username'
      elementId='exportsCountSearch'
      loading={loading}
    />
    <LinearProgress style={{ display: loading ? 'block' : 'none' }} />
  </>
)

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Stack alignItems='center'>
      <SearchUserIcon fontSize='large' color='primary' />
      <Typography color='secondary' variant='overline'>
        Search for a valid username to load results.
      </Typography>
    </Stack>
  </GridOverlay>
)

const columns: GridColDef[] = [
  {
    field: 'creationDate',
    headerName: 'Creation Date (UTC)',
    minWidth: 230,
    type: 'dateTime',
    valueGetter: ({ value }) => new Date(Number(value)),
    valueFormatter: ({ value }) => new Date(Number(value)).toLocaleString('en-US', { timeZone: 'UTC', hour12: false })
  },
  { field: 'ip', headerName: 'IP', width: 160 },
  { field: 'designID', headerName: 'Design ID', width: 200 },
  {
    field: 'freehandParts',
    headerName: 'Freehand Parts',
    type: 'string',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Stack>
        {params.value?.map((elem: string, index: number) => (
          <Typography key={index} variant='body2'>
            {elem}
          </Typography>
        ))}
      </Stack>
    )
  }
]

const ExportsCountView = () => {
  const accessToken = useSelector((state: RootState) => state.accessToken)
  const [searchValue, setSearchValue] = useState('')
  const [rows, setRows] = useState<Array<Record<string, any>>>([])
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [fetchRecords, { data, loading, error }] = useLazyQuery<GetExportsByUsernameData>(GET_EXPORTS_COUNT, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data?.getExportsByUsername) {
      setRows(data.getExportsByUsername.map((item) => ({ ...item, id: item.creationDate })))
    }
  }, [data, loading])

  useEffect(() => {
    if (error) {
      toast.error(error.message, { theme: 'colored' })
    }
  }, [error])

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    if (rows.length) setRows([])
    if (endDate) setEndDate(null)
    if (startDate) setStartDate(null)
  }

  const handleSearch = () => {
    fetchRecords({ variables: { username: searchValue.trim() } })
  }

  let filteredRows = rows
  if (startDate || endDate) {
    filteredRows = rows.filter((row) => {
      const rowDate = dayjs.utc(Number(row.creationDate))
      return (!startDate || rowDate.isAfter(startDate)) && (!endDate || rowDate.isBefore(endDate))
    })
  }

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <SoftwareFlagsMenu
          handleSearchChange={handleSearchChange}
          handleSearch={handleSearch}
          searchValue={searchValue}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <ExportsDateFilters
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Grid>
      <Grid item xs={12} height='calc(100vh - 280px)' width='100%'>
        <DataGrid
          columns={columns}
          rows={filteredRows}
          getRowId={(row) => row.creationDate}
          initialState={{ sorting: { sortModel: [{ field: 'creationDate', sort: 'desc' }] } }}
          loading={loading}
          getRowHeight={() => 'auto'}
          sx={{
            minHeight: '200px',
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '4px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '15px' }
          }}
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true }
            }
          }}
          disableRowSelectionOnClick={true}
          disableColumnFilter
        />
      </Grid>
    </Grid>
  )
}

export default ExportsCountView
