import { IconButton, TextField, InputAdornment, Stack, Button } from '@mui/material'
import { FC, FormEvent } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

export interface ISearchBarComponentProps {
  handleSearchChange: (value: string) => void
  handleSearch: () => void
  searchTextValue: string
  placeHolderSearchText: string
  elementId?: string
  loading?: boolean
}
const SearchBarComponent: FC<ISearchBarComponentProps> = ({
  handleSearch,
  handleSearchChange,
  placeHolderSearchText,
  searchTextValue,
  elementId,
  loading
}) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSearch()
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <TextField
        id={elementId ?? 'searchBar'}
        fullWidth
        disabled={loading}
        placeholder={placeHolderSearchText}
        onChange={(event) => handleSearchChange(event.target.value)}
        value={searchTextValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon color='secondary' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Stack direction='row' spacing={1}>
                <IconButton
                  color='info'
                  onClick={() => handleSearchChange('')}
                  disabled={searchTextValue === ''}
                  sx={{ display: searchTextValue === '' ? 'none' : 'flex' }}
                >
                  <ClearIcon color='info' />
                </IconButton>
                <Button variant='contained' type='submit' color='primary' disabled={searchTextValue === ''}>
                  <SearchIcon />
                </Button>
              </Stack>
            </InputAdornment>
          )
        }}
      />
    </form>
  )
}

export default SearchBarComponent
